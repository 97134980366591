import React, { useCallback, useEffect, useState } from 'react';

import BookmarksIcon from '@mui/icons-material/Bookmarks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

const emptyTemplate = {
  content: '',
  id: -1,
  type: 'fine_print',
  title: '',
};

const templateTypeMap = {
  'how-to-redeem': 'How to Redeem',
  'conditions-of-offer': 'Conditions of Offer',
  'cancellation-policy': 'Cancellation Policy',
};

interface Props {
  templates: Array<App.ExperienceOfferTemplate>;
  templateType: string;
  show: boolean;
  hide: () => void;
  onImportTemplate: (template: App.ExperienceOfferTemplate) => void;
}

function ExperienceCurationTemplateModal({ templateType, templates, onImportTemplate, hide, show }: Props) {
  const [selectedTemplate, setSelectedTemplate] = useState<App.ExperienceOfferTemplate>(emptyTemplate);
  const [visibleTemplates, setVisibleTemplates] = useState(templates);

  useEffect(() => {
    if (!templateType) {
      setVisibleTemplates(templates);
      return;
    }

    const filteredTemplates = templates.filter((template) => template.type === templateType);
    setVisibleTemplates(filteredTemplates);
  }, [templateType, templates]);

  const onChangeSelectedTemplate = useCallback(
    (e: SelectChangeEvent<number>) => {
      const id = e.target.value;
      //blank <option>
      if (!id) {
        setSelectedTemplate(emptyTemplate);
        return;
      }

      const selectedTemplate = templates.find((template) => template.id === id);
      setSelectedTemplate(selectedTemplate ?? emptyTemplate);
    },
    [templates],
  );

  const onImportTemplateClick = useCallback(() => {
    onImportTemplate(selectedTemplate);
  }, [onImportTemplate, selectedTemplate]);

  return (
    <Dialog open={show} onClose={hide}>
      <DialogTitle>Please select a template</DialogTitle>
      <DialogContent>
        <Grid mt={1} sm={6}>
          <FormControl fullWidth>
            <TextField
              value={templateTypeMap[templateType]}
              disabled
              name="Template Type"
              id="template-type"
              label="Template Type"
              InputLabelProps={{
                shrink: !!templateType,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BookmarksIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
              color="warning"
              fullWidth
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid mt={2} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="template-title-label">Template Title</InputLabel>
            <Select
              labelId="template-title-label"
              id="select-template"
              value={selectedTemplate?.id}
              label="Template Title"
              onChange={onChangeSelectedTemplate}
            >
              {visibleTemplates.length === 0 && <MenuItem value={''}>None found</MenuItem>}
              {visibleTemplates.length !== 0 && <MenuItem disabled value={''}></MenuItem>}
              {visibleTemplates.map((template) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={hide} variant="contained" color="secondary">
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onImportTemplateClick}
          disabled={visibleTemplates.length === 0 || selectedTemplate.id === -1}
        >
          Import Template
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExperienceCurationTemplateModal;
