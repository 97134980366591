import { useEffect, useState } from 'react';

import { getExperienceOfferTemplates } from '~/services/ExperiencesService';

function useExperienceOfferTemplates() {
  const [templates, setTemplates] = useState<Array<App.ExperienceOfferTemplate>>([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const fetchTemplates = async () => {
      setFetching(true);
      try {
        const templatesResult = await getExperienceOfferTemplates();
        setTemplates(templatesResult.result);
      } catch (error) {
        console.error(error);
      } finally {
        setFetching(false);
      }
    };
    fetchTemplates();
  }, []);
  return { templates, fetching };
}

export default useExperienceOfferTemplates;
