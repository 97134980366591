import React, { useCallback, useMemo, useState } from 'react';

import { theme } from '~/theme';

import BookmarksIcon from '@mui/icons-material/Bookmarks';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from '@mui/material';

import { Experiences } from '@luxuryescapes/contract-svc-experience';

import AIGeneratorButton from '~/components/AIGenerator/AIGenerateButton';
import MarkdownEditor from '~/components/Common/Forms/widgets/MarkdownEditor';
import { useUpdateExperienceValues } from '~/components/Experiences/hooks';
import useExperienceOfferTemplates from '~/components/Experiences/hooks/useExperienceOfferTemplates';

import { ORIGINAL_EXPERIENCE_CONTENT_TAB } from '~/consts/experiences';

import ExperienceCurationTemplateModal from './ExperienceCurationTemplateModal';

const templateToFieldNameMapper = (templateType: string) => {
  switch (templateType) {
    case 'how-to-redeem':
      return 'howToRedeem';
    case 'conditions-of-offer':
      return 'offerConditions';
    case 'cancellation-policy':
      return 'cancellationPolicy';
    default:
      return '';
  }
};

function ExperienceCurationAdditionalInformation() {
  const { updateValues, values, contentTab } = useUpdateExperienceValues();

  const areOriginalValues = contentTab === ORIGINAL_EXPERIENCE_CONTENT_TAB;

  const [currTemplateType, setCurrTemplateType] = useState('');
  const [showTemplateModal, setShowTemplateModal] = useState(false);

  const { templates } = useExperienceOfferTemplates();

  const templateableFields = useMemo(() => {
    return {
      thingsToKnowNew: {
        howToRedeem: templates.find((template) => template.type === 'how-to-redeem')?.type,
        offerConditions: templates.find((template) => template.type === 'conditions-of-offer')?.type,
        cancellationPolicy: templates.find((template) => template.type === 'cancellation-policy')?.type,
      },
    };
  }, [templates]);

  // TODO: below code is just a workaround to force the markdown editor to update when the value is changed
  // We must implement a proper form handler and replace MarkdownEditor with OverridableRichText/RichTextEditor
  const [dealDescriptionKey, setDealDescriptionKey] = useState(`dealDescription-${Math.random()}`);
  const [inclusionsKey, setInclusionsKey] = useState(`inclusions-${Math.random()}`);
  const applyAIContent = useCallback(
    (key: string, value: string) => {
      updateValues({ [key]: value });
      if (key === 'dealDescription') {
        setDealDescriptionKey(`dealDescription-${Math.random()}`);
      } else if (key === 'included') {
        setInclusionsKey(`inclusions-${Math.random()}`);
      }
    },
    [updateValues],
  );

  const closeTemplatesModal = useCallback(() => {
    setShowTemplateModal(false);
    setCurrTemplateType('');
  }, []);

  const onImportTemplate = useCallback(
    (template: App.ExperienceOfferTemplate) => {
      const fieldName = templateToFieldNameMapper(template.type);

      if (fieldName in values.thingsToKnowNew) {
        updateValues({
          thingsToKnowNew: {
            ...values.thingsToKnowNew,
            [fieldName]: {
              ...values.thingsToKnowNew[fieldName],
              value: template.content,
            },
          },
        });
      }
      closeTemplatesModal();
    },
    [values, closeTemplatesModal, updateValues],
  );

  const thingsToKnowFields = useMemo(() => {
    // Similar to dealDescriptionKey and inclusionsKey,
    // we need to force the markdown editor to update when the value is changed,
    // that's why we're adding a random key
    return Object.keys(values.thingsToKnowNew)
      .filter((key) => key !== 'cancellationPolicy' || !values.isNonRefundable)
      .map((key) => [key, { ...values.thingsToKnowNew[key], randomKey: `${key}-${Math.random()}` }]) as Array<
      [keyof Experiences.ThingsToKnow, Experiences.ThingsToKnowItem & { randomKey: string }]
    >;

    // Fires when currTemplateType changes to update the random key (a template was imported)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.isNonRefundable, currTemplateType]);

  const onShowTemplateModalClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setCurrTemplateType(e.currentTarget.dataset.templateType);
    setShowTemplateModal(true);
  }, []);

  const onChangeThingsToKnow = useCallback(
    (key: keyof Experiences.ThingsToKnow, value: string) => {
      if (value === values.thingsToKnowNew[key].value) {
        return;
      }
      updateValues({
        thingsToKnowNew: {
          ...values.thingsToKnowNew,
          [key]: {
            ...values.thingsToKnowNew[key],
            value,
          },
        },
      });
    },
    [updateValues, values.thingsToKnowNew],
  );

  return (
    <>
      <ExperienceCurationTemplateModal
        show={showTemplateModal}
        hide={closeTemplatesModal}
        templates={templates}
        templateType={currTemplateType}
        onImportTemplate={onImportTemplate}
      />
      {values && (
        <Stack spacing={2}>
          <Accordion id="additional-information" defaultExpanded disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="offer-fields"
              id="offer-fields"
              sx={{
                backgroundColor: theme.palette.grey[200],
              }}
            >
              <Typography variant="h5">Additional Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack mt={2} spacing={4}>
                <Stack spacing={2}>
                  <Box>
                    <MarkdownEditor
                      key={dealDescriptionKey}
                      value={values.dealDescription}
                      onChange={(value) => updateValues({ dealDescription: value })}
                      disabled={areOriginalValues}
                    />
                  </Box>
                  <AIGeneratorButton
                    context={{
                      model: 'experience',
                      field: 'description',
                      content: values.dealDescription,
                    }}
                    buttonLabel={values.dealDescription ? 'Rewrite Deal Description' : 'Generate Deal Description'}
                    onChoose={(value) => applyAIContent('dealDescription', value)}
                  />
                </Stack>
                <Box>
                  <Typography variant="body1">Short Description</Typography>
                  <MarkdownEditor
                    value={values.shortDescription}
                    onChange={(value) => updateValues({ shortDescription: value })}
                    disabled={areOriginalValues}
                  />
                </Box>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="body1">Inclusions</Typography>
                    <MarkdownEditor
                      key={inclusionsKey}
                      value={values.included}
                      onChange={(value) => updateValues({ included: value })}
                      disabled={areOriginalValues}
                    />
                  </Box>
                  <AIGeneratorButton
                    context={{
                      model: 'experience',
                      field: 'inclusions',
                      content: values.included,
                    }}
                    buttonLabel={values.included ? 'Rewrite Inclusions' : 'Generate Inclusions'}
                    onChoose={(value) => applyAIContent('included', value)}
                  />
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion id="thingsToKnow" defaultExpanded disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="things-to-know-fields"
              id="things-to-know-fields"
              sx={{
                backgroundColor: theme.palette.grey[200],
              }}
            >
              <Typography variant="h5">Things To Know</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Stack mt={2} spacing={4}>
                {thingsToKnowFields.map(([key, item]) => (
                  <Stack spacing={1} key={item.randomKey}>
                    <Box>
                      <Typography variant="body1">{item.label}</Typography>
                      <MarkdownEditor
                        key={contentTab}
                        value={item.value}
                        onChange={(value) => onChangeThingsToKnow(key, value)}
                        disabled={areOriginalValues}
                      />
                    </Box>
                    {!!templateableFields.thingsToKnowNew[key] && (
                      <Box>
                        <Button
                          variant="outlined"
                          startIcon={<BookmarksIcon />}
                          color="secondary"
                          onClick={onShowTemplateModalClick}
                          data-template-type={templateableFields.thingsToKnowNew[key]}
                        >
                          Show Templates
                        </Button>
                      </Box>
                    )}
                  </Stack>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      )}
    </>
  );
}

export default ExperienceCurationAdditionalInformation;
