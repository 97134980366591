import React, { useCallback, useEffect, useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PostAddIcon from '@mui/icons-material/PostAdd';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { CUSTOM_OFFER_PDF, INVOICE, PUBLIC_ATTACHMENT } from '~/consts/notes';
import { ROLE_TOUR_COORDINATOR } from '~/consts/roles';

import useCurrentUser from '~/hooks/useCurrentUser';

import { deleteOrderNote, getInvoiceUrl, getOrderNotes } from '~/services/OrdersService';
import { formatDateOrdinalWithClock } from '~/services/TimeService';

import { isAdmin } from '~/utils/adminPermission';

import addUserData from '../../../utils/addUserData';

function canDeleteOrderNote(user: App.User) {
  return isAdmin(user) || user?.roles?.includes(ROLE_TOUR_COORDINATOR);
}

function deleteRow(row, refreshData, user) {
  return async function () {
    if (!canDeleteOrderNote(user)) {
      alert('Only admin users can delete');
      return;
    }
    if (window.confirm('Are you sure you wish to delete this comment?')) {
      try {
        await deleteOrderNote(row.fk_order_id, row.id_notes);
        refreshData();
      } catch (e) {
        alert(e.message);
      }
    }
  };
}

interface Props {
  orderId: string;
  refreshData: () => void;
  customer: App.User;
  offerId?: string;
}

export default function OrderDetailNotes({ orderId, refreshData, customer, offerId }: Props) {
  const { user } = useCurrentUser();
  const theme = useTheme();

  const [result, setResult] = useState([]);
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [open, setOpen] = useState(true);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [error, setError] = useState<string>('');

  let customOfferParams = '';
  if (offerId && customer?.id_member) {
    customOfferParams = `?offer_id=${offerId}&user_id=${customer.id_member}`;
  }

  const columns = useMemo<Array<GridColDef>>(
    () => [
      {
        field: 'fk_added_by_id',
        headerName: 'User',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        valueGetter: (_value, row) => row.fk_added_by_id?.fullName,
        display: 'flex',
      },
      {
        field: 'created_at',
        headerName: 'Created At',
        disableColumnMenu: true,
        flex: 1,
        valueFormatter: (value) => formatDateOrdinalWithClock(value),
        display: 'flex',
      },
      {
        field: 'comment_type',
        headerName: 'Type',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: 'flex',
      },
      {
        field: 'comment',
        headerName: 'Comment',
        sortable: false,
        disableColumnMenu: true,
        flex: 3,
        display: 'flex',
      },
      {
        field: 'invoice',
        headerName: 'Attached PDF',
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          if (
            params.row.comment_type === INVOICE ||
            params.row.comment_type === CUSTOM_OFFER_PDF ||
            params.row.comment_type === PUBLIC_ATTACHMENT
          ) {
            return (
              <DownloadForOfflineOutlinedIcon
                onClick={() => {
                  getInvoiceUrl(params.row.fk_order_id, params.row.id_notes, params.row.comment);
                }}
              />
            );
          }
          return null;
        },
        display: 'flex',
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        renderCell: (params) => {
          return (
            <IconButton onClick={deleteRow(params.row, refreshData, user)}>
              <DeleteIcon />
            </IconButton>
          );
        },
        display: 'flex',
      },
    ],
    [refreshData, user],
  );

  const fetchNotes = useCallback(async () => {
    try {
      setLoadingState('loading');
      setError('');

      const orderNotes = await getOrderNotes(orderId);
      const result = await addUserData(orderNotes, 'fk_added_by_id');

      setLoadingState('success');
      setResult(result);

      // Only close accordion automatically on initial load when empty
      if (result.length === 0 && !initialLoadDone) {
        setOpen(false);
      }

      setInitialLoadDone(true);
    } catch (error) {
      setLoadingState('failed');
      setError(`Can't load order notes: ${error.message}`);
    }
  }, [orderId, initialLoadDone]);

  const handleChange = useCallback((_event: React.SyntheticEvent | null, isExpanded: boolean) => {
    setOpen(isExpanded);
  }, []);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  return (
    <Accordion
      id="order-notes"
      expanded={open}
      onChange={handleChange}
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center">
            <StickyNote2OutlinedIcon sx={{ mr: 1 }} />
            <Typography variant="h6">Notes</Typography>
          </Box>
          <IconButton
            href={`/purchases/${orderId}/notes/add${customOfferParams}`}
            title="Add order notes"
            onClick={(e) => e.stopPropagation()}
            sx={{ marginRight: 2, color: theme.palette.primary.main }}
          >
            <PostAddIcon />
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {loadingState === 'loading' && <Spinner size={32} />}
        {loadingState === 'failed' && <ErrorDisplay message={error} />}
        {loadingState === 'success' && (
          <DataGrid
            columns={columns}
            density="compact"
            rows={result}
            getRowId={(row) => row.id_notes}
            autoHeight
            pageSizeOptions={[]}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            getRowHeight={() => 'auto'}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}
